import { TabBtn } from '@playbooks/interface/buttons';
import { Div } from '@playbooks/interface/html';
import { capitalize } from 'utils';

const SearchResultsSubnav = ({ tableName, tableNames = [], onSelect }) => {
	// Methods
	const isActive = value => {
		return tableName === value;
	};

	// Render
	return (
		<Div border='border-b' display='flex-start' space='space-x-2' overflow='overflow-x-scroll'>
			<TabBtn size='p-3' active={isActive('')} onClick={() => onSelect('')}>
				All
			</TabBtn>
			{tableNames.map((tableName, i) => (
				<TabBtn key={i} size='p-3' active={isActive(tableName)} onClick={() => onSelect(tableName)}>
					{capitalize(tableName)}
				</TabBtn>
			))}
		</Div>
	);
};

export { SearchResultsSubnav };
