import { BountySearchCard } from 'components/bounty/bounty-card';
import { FrameworkSearchCard } from 'components/framework/framework-card';
import { LanguageSearchCard } from 'components/language/language-card';
import { RepoSearchCard } from 'components/repo/repo-card';
import { StackSearchCard } from 'components/stack/stack-card';
import { TeamSearchCard } from 'components/team/team-card';
import { ToolSearchCard } from 'components/tool/tool-card';
import { TopicSearchCard } from 'components/topic/topic-card';
import { UserSearchCard } from 'components/user/user-card';
import { SearchBtnCard } from 'molecules/cards/search-card';

export const SearchPillCard = ({ icon, hit, onClick, tailwind }) => {
	return (
		<SearchBtnCard
			icon={icon}
			text={hit.title || hit.name}
			onClick={() => onClick(hit)}
			modelName={hit.modelName}
			tailwind={{
				card: { border: 'border', borderRadius: 'rounded-md', spacing: 'p-2' },
				header: { bgColor: '', width: 'w-6' },
				icon: { fontSize: 'text-base' },
				...tailwind,
			}}
		/>
	);
};

export const SearchPreviewCard = ({ modelName, icon, hit, onClick, tailwind }) => {
	return (
		<SearchBtnCard
			icon={icon}
			title={hit.title || hit.name || hit.query}
			modelName={modelName}
			onClick={() => onClick(hit)}
			tailwind={{ card: { spacing: 'p-4' }, ...tailwind }}
		/>
	);
};

export const SearchSearchCard = ({ hit, onClick, tailwind }) => {
	const props = { onClick, tailwind };

	// Render
	switch (hit.index) {
		case 'bounties':
			return <BountySearchCard bounty={hit} {...props} />;

		case 'frameworks':
			return <FrameworkSearchCard framework={hit} {...props} />;

		case 'languages':
			return <LanguageSearchCard language={hit} {...props} />;

		case 'repos':
			return <RepoSearchCard repo={hit} {...props} />;

		case 'stacks':
			return <StackSearchCard stack={hit} {...props} />;

		case 'teams':
			return <TeamSearchCard team={hit} {...props} />;

		case 'tools':
			return <ToolSearchCard tool={hit} {...props} />;

		case 'topics':
			return <TopicSearchCard topic={hit} {...props} />;

		case 'users':
			return <UserSearchCard user={hit} {...props} />;
	}
};
