import { Div } from '@playbooks/interface/html';
import { SearchDrop } from 'components/search/search-drop';

const AppSearch = () => {
	// Render
	return (
		<Div display='hidden md:block' flex='grow'>
			<SearchDrop
				subnav={['bounties', 'repos', 'stacks', 'teams', 'users']}
				tableNames={['bounties', 'frameworks', 'languages', 'repos', 'stacks', 'tools', 'topics', 'teams', 'users']}
				placeholder='Search marketplace...'
				tailwind={{ search: { inputPrepend: { spacing: 'px-4' }, input: { spacing: 'px-0 py-2.5' } } }}
			/>
		</Div>
	);
};

export { AppSearch };
