import { Fragment, useMemo } from 'react';

import { SearchAllSection } from 'components/search/search-all-section';
import { SearchRecentSection } from 'components/search/search-recent-section';
import { SearchTrendingSection } from 'components/search/search-trending-section';
import { useSearch, useStorage } from 'contexts';

const SearchResultsPreview = ({ query, hits = [], loading, tableName, tableNames }) => {
	const search = useSearch();
	const storage = useStorage();

	// Computed
	const recent = useMemo(() => {
		const queries = storage.getValue('search') || [];
		return queries
			.filter(v => (tableName ? tableName === v.modelName : true))
			.filter(v => (tableNames.length > 0 ? tableNames.includes(v.modelName) : true))
			.sort((a, b) => (a.date < b.date ? 1 : -1));
	}, [storage.getValue('search'), tableName, tableNames]);

	// Methods

	// Render
	return (
		<Fragment>
			{query.length >= 3 ? (
				<SearchAllSection hits={hits} query={query} loading={loading} onClick={result => search.onClick('/', result)} />
			) : (
				<Fragment>
					{recent.length > 0 ? (
						<SearchRecentSection
							api={{ page: 0, pageSize: 6 }}
							tableName={tableName}
							tableNames={tableNames}
							onClick={result => search.onClick('/', result)}
						/>
					) : (
						<SearchTrendingSection
							query={query}
							api={{ page: 0, pageSize: 6 }}
							tableName={tableName}
							tableNames={tableNames}
							onClick={result => search.onClick('/', result)}
						/>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export { SearchResultsPreview };
