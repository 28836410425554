import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { Drop, DropMenu } from '@playbooks/interface/drops';
import { Form } from '@playbooks/interface/forms';
import { InputAppend } from '@playbooks/interface/input-groups';
import { appParams } from 'api';
import { SearchResultsPreview } from 'components/search/search-results-preview';
import { SearchResultsSubnav } from 'components/search/search-results-subnav';
import { useKeyPress, useQuery } from 'hooks';
import { SearchForm } from 'molecules/forms';
import { AlgoliaService } from 'services';
import { timeout } from 'utils';

const SearchDrop = ({ subnav, tableNames, placeholder = 'Search for anything...', tailwind }) => {
	const [query, setQuery] = useState('');
	const [tableName, setTableName] = useState('');
	const [results, setResults] = useState([]);
	const [params, setParams] = useState({ ...appParams, status: 'active', pageSize: 3 });
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const [open, setOpen] = useState(false);
	const ref = useRef(null);
	const router = useRouter();

	// Computed
	const client = new AlgoliaService({ tableName, tableNames });

	const computedParams = useMemo(() => {
		return tableNames.map(tableName => {
			return ['bounties', 'repos', 'stacks', 'teams', 'users'].includes(tableName)
				? params
				: { ...params, visibility: null };
		});
	}, [tableNames, params]);

	// Hooks
	useEffect(() => {
		if (query.length >= 3) fetchData(query);
	}, [params, query]);

	useEffect(() => {
		if (open) onClose();
	}, [router.asPath]);

	useEffect(() => {
		open ? onReady() : onClear();
	}, [open]);

	useKeyPress(onKeyDown, [router.asPath]);

	// Actions
	const [fetchData, loading] = useQuery(async () => {
		tableName ? await searchTable() : await searchTables();
	});

	// Functions
	function onKeyDown(e) {
		if (e.target.dataset.name === 'FormInput') return;
		if (e.keyCode === 220) onOpen();
	}

	// Methods
	const searchTable = async () => {
		const response = await client.queryIndex(query, params);
		setResults(response.data);
		setMeta(response.meta);
	};

	const searchTables = async () => {
		const response = await client.queryIndexes(query, computedParams);
		setResults(response.map(record => record.data).flat());
	};

	const onOpen = () => {
		setOpen(true);
	};

	const onReady = async () => {
		await timeout(300);
		ref.current && ref.current.focus();
	};

	const onSelect = tableName => {
		setTableName(tableName);
		setParams({ ...params, pageSize: tableName ? 6 : 2 });
	};

	const onClose = () => {
		onClear();
		setOpen(false);
	};

	const onClear = () => {
		setQuery('');
		setResults([]);
	};

	const onSubmit = e => {
		e.preventDefault();
		switch (tableName) {
			case 'bounties':
				return router.push(`/search/bounties?query=${query}`);

			case 'stacks':
				return router.push(`/search/stacks?query=${query}`);

			case 'repos':
				return router.push(`/search/repos?query=${query}`);

			case 'teams':
				return router.push(`/search/teams?query=${query}`);

			case 'users':
				return router.push(`/search/users?query=${query}`);

			default:
				return router.push(`/search?query=${query}`);
		}
	};

	// Render
	return (
		<Drop open={open} setOpen={onClose} width='w-full'>
			<Form onSubmit={onSubmit}>
				<SearchForm
					id='drop'
					ref={ref}
					delay={300}
					query={query}
					setQuery={setQuery}
					placeholder={placeholder}
					onFocus={onOpen}
					onClear={onClear}
					tailwind={tailwind?.search}
					elements={{
						inputAppend: (
							<InputAppend
								border='border'
								borderRadius='rounded-md'
								color='gray-400'
								fontSize='text-xs'
								spacing='m-2 px-2 py-1'
								onClick={() => query.length >= 3 && onClear()}>
								{query.length < 3 ? '\\' : 'ESC'}
							</InputAppend>
						),
					}}
				/>
			</Form>
			<DropMenu open={open} inset='left-0'>
				<SearchResultsSubnav tableName={tableName} tableNames={subnav} onSelect={onSelect} />
				<SearchResultsPreview
					query={query}
					hits={results}
					loading={loading}
					tableName={tableName}
					tableNames={tableNames}
				/>
			</DropMenu>
		</Drop>
	);
};

export { SearchDrop };
