import { useRouter } from 'next/router';

import { Banner, BannerBody, BannerIcon, BannerText } from '@playbooks/interface/banners';
import { AccentBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import { useSession, useStorage } from 'contexts';
import { Skeleton } from 'molecules/skeletons';

const AdminBanner = () => {
	const router = useRouter();
	const session = useSession();
	const storage = useStorage();
	const tempToken = storage.loaded && storage.storage.tempToken?.id;

	// Methods
	const onClick = () => {
		storage.storeValue('tempAccount', {});
		storage.storeValue('tempToken', {});
		window.close();
	};

	// Render
	if (!tempToken || router.asPath.includes('/admin')) return null;
	return (
		<Banner bgColor='bg-white dark:bg-gray-900' display='flex-between'>
			<BannerBody>
				<BannerIcon type='fad' icon='user-crown' />
				<BannerText>
					You're acting as{' '}
					{session.loading ? <Skeleton type='basic' className='mr-2 w-20' /> : <Span>{session.user.name}.</Span>}
				</BannerText>
			</BannerBody>
			<AccentBtn size='xs' prevIcon='xmark' onClick={onClick}>
				Exit
			</AccentBtn>
		</Banner>
	);
};

export { AdminBanner };
